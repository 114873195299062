.navbg {
  background: #F4F4F4;
  box-shadow: -1px 1px 20px rgb(0 0 0 / 20%);
  color: #135896;
}

.nav-text {
  color: #135896;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0 !important;
  box-shadow: none;
}
